<template>
	<v-dialog :value="showCreateClientDialog" max-width="600" @click:outside="showCreateClientDialog = false">
		<v-card :loading="createDialogLoading">
			<v-card-title>
				Регистрация клиента
			</v-card-title>
			<v-card-text>
				<v-form ref="newCustomerForm">
					<v-text-field
						v-model="newCustomer.surname"
						label="Фамилия"
						:rules="[
							val => !!(val && val.length) || 'Поле не может быть пустым',
							val => !!(val && val.length && val.length <= 40) || 'Значение поля не может превышать длину 40 символов',
							val => !!(val && !val.match(/[^a-zA-Zа-яА-Я ]+/)) || 'Значение поля может состоять из букв русского или латинского алфавита'
						]"
					></v-text-field>
					<v-text-field
						v-model="newCustomer.name"
						label="Имя"
						:rules="[
							val => !!(val && val.length) || 'Поле не может быть пустым',
							val => !!(val && val.length && val.length <= 40) || 'Значение поля не может превышать длину 40 символов',
							val => !!(val && !val.match(/[^a-zA-Zа-яА-Я ]+/)) || 'Значение поля может состоять из букв русского или латинского алфавита'
						]"
					></v-text-field>
					<v-text-field
						v-model="newCustomer.middleName"
						label="Отчество"
						:rules="[
							val => !!((val && val.length <= 40) || !val || val == '') || 'Поле слишком длинное',
							val => !!(!val || val == '' || (val && !val.match(/[^a-zA-Zа-яА-Я ]+/))) || 'Значение поля может состоять из букв русского или латинского алфавита'
						]"
					></v-text-field>
          <v-select
              v-model="newCustomer.whose"
              label="Кем является"
              :items="whoseItems"
          ></v-select>
					<v-menu
						ref="menu"
						v-model="birthdayMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="newCustomer.birthday"
								label="Дата рождения"
								name="register-birthday"
								v-mask="'##.##.####'"
								v-bind="attrs"
								v-on="on"
								autocomplete="off"
								:rules="[val => !!(val && val.length == 10) || 'Не верная дата']"
							></v-text-field>
						</template>
						<v-date-picker
							ref="picker"
							v-model="datePickerBirthday"
							:max="new Date().toISOString().substr(0, 10)"
							min="1900-01-01"
							color="primary"
							flat
							reactive
							locale="ru-RU"
							no-title
						></v-date-picker>
					</v-menu>
				</v-form>
			</v-card-text>
			<v-card-actions style="justify-content: flex-end">
				<v-btn :disabled="createDialogLoading" text @click="closeNewCustomerDialogs()">Отмена</v-btn>
				<v-btn :loading="createDialogLoading" color="primary" text @click="confirmSaveNewCustomer()">Сохранить</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog :value="showConfirmationCreateCustomer" max-width="600" @click:outside="showConfirmationCreateCustomer = false">
			<v-card :loading="createDialogLoading">
				<v-card-title>Подтверждение</v-card-title>
				<v-card-text>
					<div class="text-body-1">
						Фамилия: <span
						class="font-weight-bold">{{ newCustomer.surname ? newCustomer.surname : '' }}</span>
					</div>
					<div class="text-body-1">
						Имя: <span class="font-weight-bold">{{ newCustomer.name ? newCustomer.name : '' }}</span>
					</div>
					<div class="text-body-1">
						Отчество: <span
						class="font-weight-bold">{{ newCustomer.middleName ? newCustomer.middleName : '' }}</span>
					</div>
          <div class="text-body-1">
            Кем является: <span
              class="font-weight-bold">{{ newCustomer.whose ? newCustomer.whose : '' }}</span>
          </div>
					<div class="text-body-1">
						Дата рождения: <span class="font-weight-bold">{{ newCustomer.birthday }}</span>
					</div>
				</v-card-text>
				<v-card-actions style="justify-content: flex-end">
					<v-btn :disabled="createDialogLoading" text @click="closeNewCustomerDialogs()">Отмена</v-btn>
					<v-btn :disabled="createDialogLoading" text @click="editNewCustomer()">Редактировать</v-btn>
					<v-btn :loading="createDialogLoading" color="primary" text @click="saveNewCustomer()">Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'CreateRepresentativeDialog',
	data: function () {
		return {
			createDialogLoading: false,
			datePickerBirthday: null,
			birthdayMenu: false,

			newCustomer: {
				birthday: null,
				surname: null,
				name: null,
				middleName: null,
        whose: null,
			},
      whoseItems: [
          'Внучка',
          'Бабушка',
          'Брат',
          'Внук',
          'Дедушка',
          'Дочь',
          'Другая степень родства',
          'Жена',
          'Зять',
          'Иной родственник',
          'Мать',
          'Мачеха',
          'Муж',
          'Невестка (сноха)',
          'Опекун',
          'Отец',
          'Отчим',
          'Падчерица',
          'Пасынок',
          'Попечитель',
          'Свекор',
          'Свекровь',
          'Сестра',
          'Сын',
          'Тесть',
          'Теща'
      ],
			showCreateClientDialog: false,
			showConfirmationCreateCustomer: false
		}
	},
	
	watch: {
		datePickerBirthday(newVal) {
			if (!newVal) {
				return this.newCustomer.birthday = newVal;
			}
			let time = this.$moment(newVal).format('DD.MM.YYYY');
			if (time != 'Invalid date') {
				return this.newCustomer.birthday = time;
			} else {
				return this.newCustomer.birthday = newVal;
			}
		},
		
		value(newValue) {
			this.showCreateClientDialog = newValue;
		},
		
		showCreateClientDialog(newValue) {
			this.$emit('input', newValue);
			return newValue;
		}
	},
	
	methods: {
		...mapActions({
			setRepresentatives: 'setRepresentativesAction',
			setCurrentUser: 'selectUser'
		}),
		
		selectUser(idUser) {
			this.setCurrentUser(idUser);
		},
		
		// Открывает диалог
		confirmSaveNewCustomer() {
			// Валидация формы
			if (!this.$refs.newCustomerForm.validate()) {
				return;
			}
			this.showConfirmationCreateCustomer = true;
		},
		
		editNewCustomer() {
			this.showCreateClientDialog = true;
			this.showConfirmationCreateCustomer = false;
		},
		
		// Вернуть отформатированную дату записи
		birthdatFormatDate(dateFrom){
			return this.$moment(dateFrom).format("DD.MM.YYYY");
		},
		
		// Открыть или закрыть модалку создание клиента
		toggleCustomerCreateDialog() {
			this.showCreateClientDialog = !this.showCreateClientDialog;
		},
		
		closeNewCustomerDialogs() {
			this.showCreateClientDialog = false;
			this.showConfirmationCreateCustomer = false;
		},
		
		// Сохраняет нового клиента
		saveNewCustomer() {
			this.createDialogLoading = true;
			let customer = {
				...this.newCustomer
			}
			customer.birthday = this.$moment(customer.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD');
			this.$http.post(window.config.apiUrl + '/customers/representative-create-customer', {
				customer: customer,
        whose: this.whose
			}).then( ({data}) => {
				if (data.status == 'success') {
					return this.setRepresentatives([...this.getRepresentatives, {...customer, id: data.id}]);
				}
			}).then(_ => {
				this.showCreateClientDialog = false;
				this.showConfirmationCreateCustomer = false;
				this.newCustomer = {
					birthday: null,
					surname: null,
					name: null,
					middleName: null
				};
				this.$refs.newCustomerForm.resetValidation()
			})
				.catch( error => console.log(error) )
				.finally( _ => this.createDialogLoading = false );
		}
	},
	
	async mounted() {
		this.showCreateClientDialog = this.value;
	},
	
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	
	computed: {
		...mapGetters([
			'getRepresentatives',
			'getUser',
			'activeUser',
			'getUser'
		]),
	},
	
	components: {
	}
}
</script>
