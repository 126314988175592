import axios from "axios"

export default {
  getUsers({commit, state}, idSpecial=''){
    commit('setUsers', {});
    return axios({
      url: window.config.apiUrl + 
      '/widgets/get-users?dateFrom=' + state.createAppointmentNew.date + 
      '&dateTo=' + state.createAppointmentNew.date + 
      '&showFreeIntervals=true' +
      '&idSpecial=' + idSpecial +
      '&idFilial=' + (state.createAppointmentNew.filial ? state.createAppointmentNew.filial : '') +
      '&idCustomer=' + state.user.id +
      '&customerAge=' + (state.createAppointmentNew.age ? state.createAppointmentNew.age : '') +
      (state.createAppointmentNew.service ? '&idService=' + state.createAppointmentNew.service.id : ''),
      method: 'GET',
    })
    .then(res => {
      commit('setUsers', res.data.users)
    })
    // .catch(err => {console.log(err)})
  },
  getUser({state}, {dateTo, showFreeIntervals, showUserServices, idCustomer }){
    return axios({
      url: window.config.apiUrl + 
      '/widgets/get-users?idUser=' + state.createAppointmentNew.userId +
      '&dateAppointment=' + state.createAppointmentNew.date + 
      '&dateTo=' + dateTo +
      '&idFilial=' + (state.createAppointmentNew.filial ? state.createAppointmentNew.filial : '') +
      '&idCustomer=' + (idCustomer ? idCustomer : state.user.id) +
      '&showFreeIntervals=' + showFreeIntervals +
      '&showUserServices=' + showUserServices,
      method: 'GET',
    })
    .then(res => res.data.users[state.createAppointmentNew.userId])
    .catch(err => {console.log(err)})
  },
  getUsersSpecials(ctx, q){
    return axios({
      url: window.config.apiUrl + '/widgets/search-users?searchQuery=' + q,
      method: 'GET',
    })
  },
  getServices(ctx, q){
    return axios({
      url: window.config.apiUrl + '/widgets/search-services?userServices=true&searchQuery=' + q,
      method: 'GET',
    })
  },
  getServiceById({commit}, id){
    return axios({
      url: window.config.apiUrl + '/widgets/search-services?idService=' + id,
      method: 'GET',
    }).then(res => {
      if (res.data.services.length) {
        commit('setService', res.data.services[0])
      }
    })
  }
}