<template>
  <div>
    <h6>Debug</h6>
    <h6>App version:: {{appVersion}}</h6>
    <a href="javascript: window.open('https://ya.ru/', '_system');">Внешняя ссылка window.open system</a><br>
    <a href="javascript: window.open('https://ya.ru/', '_blank');">Внешняя ссылка window.open blank</a><br>
    <v-btn @click="getNotifyPush()">Получить токен PUSH Android</v-btn>
    <v-btn @click="getNotifyPushIos()">Получить токен PUSH IOS</v-btn>
    <v-btn @click="toMarket()">To market</v-btn>

    <a href="market://details?id=com.kibersoft.doctorznaet" target="_blank">Market app</a>
    <textarea id="push_token" placeholder="Push token"></textarea>
  </div>
</template>

<script >

import {mapGetters} from "vuex";

export default {
  data() {
    return {
      title: 'Карта проезда',
      dialog: false,
    }
  },
  computed: {
    ...mapGetters([
      'STATIC'
    ])
  },
  methods: {
    toBack() {
      this.$router.push('/')
    },
    getNotifyPush(){
      try {
        alert('Try Android')

        window.FirebasePlugin.getToken(function(token) {
          document.getElementById('push_token').value = token;
          console.log(token);
          window.pushToken = token;
          alert(token)
        }, function(error) {
          alert(error);
        });

        window.FirebasePlugin.onTokenRefresh(function(token) {
          // save this server-side and use it to push notifications to this device
          alert('r_' + token);
          document.getElementById('push_token').value = token;
          console.log(token);
          window.pushToken = token;
        }, function(error) {
          alert(error);
        });

      } catch (e){
        alert(e.message);
        alert(e.stack);
        alert(e.name);

      }
    },
    getOS(){
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
      return os;
    },

    getNotifyPushIos(){
      try {
        alert('Try IOS')
        window.FCMPlugin.getToken(function(token){
          alert(token)
          window.pushToken = token;
          document.getElementById('push_token').value = token;
        });
      } catch (e){
        alert(e.message);
        alert(e.stack);
        alert(e.name);
      }
    }

  },
  created: function() {
    // Событие отправки сообщения
    this.getNotifyPush();
  }
}


// const toMarket = function(){
//   window.open('market://details?id=com.kibersoft.doctorznaet', '_blank')
// }




</script>