<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <v-card
        class="mx-auto"
        elevation="0"
    >
      <v-card-text class="pb-0">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex flex-column align-items-start">
            <v-btn
                v-if="!this.order.payStatus || this.order.payStatus < 1"
                text
                color="error"
                class="mb-2"
                x-small
                @click.stop="dialogDelete = true"
            >
              Отменить заказ
            </v-btn>
            <div>
              <v-chip
                  :color="order.status.color"
                  small
              >
                {{ order.status.name }}
              </v-chip>
            </div>
            <div v-if="showCode && order.code">
              <span v-if="order.code" class="badge badge-info mt-2">Код получения: {{ order.code }}</span>
            </div>
          </div>

          <span class="d-flex flex-column align-items-end">
                        <span>№ {{ order.number }}</span>                        
                        <div class="text-right">                            
                            <span :class="'ml-2 badge badge-' + payStatusColor ">{{ payStatusLabel }}</span> 
                        </div>
                    </span>
        </div>
        <div class="row">
          <div class="col-12 col-md-7 pb-0">
            <div class="text--primary">
              Дата оформления: {{ dateOrder }}
              <span v-if="order.filial.name && !order.delivery"><br/> Пункт выдачи: {{
                  order.filial.name
                }} {{ order.filial.address ? order.filial.address : '' }}</span>
              <br/>Количество товаров: <b>{{ order.items? order.items.length : 0 }}</b>
              <span v-if="order.comment"><br/> Комментарий: {{ order.comment }}</span>
            </div>
          </div>
          <div class="col-12 col-md-5 pb-0 ">
            <div class="text-right">
              <div v-if="!order.missing" class="d-flex justify-content-md-end align-baseline">
                <span class="subtitle-1 mr-2 ">Итого: </span>
                <div class="text-h6 font-weight-medium text--primary">
                  <PriceTotal :price="totalPrice"
                              :approximately="isApproximately"/>                
                </div>              
              </div>           
              <div v-else class="d-flex justify-content-md-end align-baseline">
                <span class="subtitle-1 mr-2 ">Товара из списка нет в наличии</span>
              </div>
              <div class="d-flex justify-content-md-end align-baseline" v-if="order.bonus">
                <span class="text-caption">Списано {{ order.bonus }} {{ order.bonus == 1 ? 'бонус' : (order.bonus < 5 ? 'бонуса' : 'бонусов') }}</span>
              </div>
            </div>
            <div v-if="!order.missing && order.delivery" class="d-flex justify-content-md-end align-baseline">
              <span class="subtitle-1 mr-2 ">Доставка: </span>
              <div class="text-subtitle-2 font-weight-medium text--primary">
                <PriceTotal :price="order.delivery.price" :text-on-zero="approved ? 'Бесплатно': 'Нет цены'"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.delivery" class="grey lighten-3 p-3 rounded mt-2">
          <h6>Доставка
            <span class="badge badge-secondary">{{ order.delivery.alias }}</span>
          </h6>
          <div class="text-dark">{{
              order.delivery.id === 'courier' ? 'Доставка курьером по адресу' : 'Пункт выдачи'
            }}:
            <b>{{ order.delivery.address }}</b>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue-grey lighten-2"
            text
            @click="show = !show"
        >
          Подробнее
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <div v-if="!order.missing && order.payStatus < 1">
          <div>
            <v-chip
                v-if="isApproximately"
                small
            >
              Ожидайте звонка менеджера
            </v-chip>
          </div>

          <v-btn
              v-if="!isApproximately && order.status.tag == 'expectPayment' && isAcquiring && (!order.delivery || (order.delivery && order.delivery.approved))"
              color="success"
              :disabled="loadingPay"
              text
              @click="isBonus ? readyPay()  : pay()"
          >
            <span v-if="loadingPay"><i class="fas fa-circle-notch fa-spin"></i></span>
            <span v-else>{{ isBonus ? 'К оплате' : 'Оплатить' }}</span>
          </v-btn>
        </div>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">

          <v-card-text>
            <div class="grey lighten-4 p-3 rounded">
              <div :key="index" v-for="(orderItem,index) in order.items">
                <OrderItem :item="orderItem" :payStatus="order.payStatus"/>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>

    </v-card>
    <div class="pr-2 pl-2">
      <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          color="deep-purple accent-4"
          dark
          dismissible
      >
        {{ alertText }};
      </v-alert>
    </div>
    <v-dialog
        v-model="dialogDelete"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление
        </v-card-title>

        <v-card-text>
          Вы уверены что хотите удалить заказ №{{ order.number }} c товарами:
          <ul>
            <li :key="index" v-for="(orderItem,index) in order.items">
              {{ orderItem.name }};
            </li>
          </ul>

          <v-text-field
              v-model="reason"
              hide-details="auto"
              label="Пожалуйста, укажите причину отмены заказа"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="dialogDelete = false"
          >
            Отмена
          </v-btn>

          <v-btn
              color="error darken-1"
              text
              @click="deleteOrder()"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="payDialog"
      max-width="400"
    >
      <v-card>
        
          <v-form ref="payment-discount-form">
            <v-expansion-panels
                :disabled="promocodeLoading || disableOptions"
                accordion
                flat
                tile
                :style="{'--exp-color': vuetifyColors.grey.lighten1}"
                v-model="panelModel"
              >          
                <v-expansion-panel class="custom-expansion-panel" :key="0">
            
                  <v-expansion-panel-header>
                    <div><v-icon small left>fas fa-ruble-sign</v-icon>Использовать бонусные рубли</div>
                  </v-expansion-panel-header>
            
                  <v-expansion-panel-content>
                    <div class="text-caption d-flex flex-wrap justify-content-between">
                      <span>Баланс бонусных рублей:&nbsp;</span>
                      <template v-if="bonusBalanceLoading">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-center px-4">
                          <v-progress-linear indeterminate rounded color="primary"></v-progress-linear>
                        </div>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold">
                            {{ bonusBalance }}
                        <span style="font-size: 90%">&nbsp;₽</span></span>
                      </template>
                    </div>
                    <div class="text-caption d-flex flex-wrap justify-content-between mb-2">
                      <span>Доступно для списания:&nbsp;</span>
                      <template v-if="bonusBalanceLoading">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-center px-4">
                          <v-progress-linear indeterminate rounded color="primary"></v-progress-linear>
                        </div>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold">
                            {{ maxBonusThatCanBeSpend }}
                        <span style="font-size: 90%">&nbsp;₽</span></span>
                      </template>
                    </div>
                    <v-text-field
                      id="payment-bonus-amount"
                      placeholder="Количество бонусных рублей"
                      filled
                      dense
                      autocomplete="off"
                      v-model="bonusRoublesAmount"
                      :rules="[
                        value => value <= maxBonusThatCanBeSpend ? true : `Вы не можете использовать больше ${maxBonusThatCanBeSpend} ₽`,
                        value => (value !== '' && value != null && value > 0) ? true : `Вы не можете списать это количество`,
                        value => (value == Math.trunc(value)) ? true : `Можно списывать только целое количество бонусных рублей`
                      ]"
                      type="number"
                    >                     
                    </v-text-field>
                  </v-expansion-panel-content>
          
                </v-expansion-panel>
        
            </v-expansion-panels>
          </v-form>

          <div class="pl-3 pr-3 text-caption">

            <div v-if="isBonus && panelModel === 0 && maxBonusThatCanBeSpend >= bonusRoublesAmount && 
                  bonusRoublesAmount !== '' && bonusRoublesAmount != null && bonusRoublesAmount > 0 &&
                  bonusRoublesAmount == Math.trunc(bonusRoublesAmount)">
              <div class="d-flex flex-wrap justify-content-between">            
                <span>Цена:&nbsp;</span>
                <PriceTotal :price="totalPrice" :approximately="isApproximately"/>
              </div>
              <div class="d-flex flex-wrap justify-content-between">            
                <span>Бонусы:&nbsp;</span>
                <PriceTotal :price="bonusRoublesAmount * -100"/>              
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between">            
              <span>Итого:&nbsp;</span>
              <PriceTotal :price="totalPriceWithBonus" :approximately="isApproximately"/>
            </div>
          </div>          
        <v-card-actions>
          <v-btn color="success" block :disabled="promocodeLoading" @click="promocodeLoading = true; pay()">
            <span v-if="promocodeLoading"><i class="fas fa-circle-notch fa-spin"></i></span>
            <span v-else>Оплатить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import moment from 'moment'
import OrderItem from '@/components/orders/OrderItem.vue'
import PriceTotal from '@/components/orders/PriceTotal.vue'
import {orders} from '@/services/api'

export default {
  name: 'OrderLine',
  components: {
    OrderItem,
    PriceTotal
  },
  data() {
    return {
      alert: false,
      alertText: 'Ошибка при формировании счёта',
      show: false,
      loadingPay: false,
      dialogDelete: false,
      reason: '',
      isAcquiring: false,
      isBonus: false,
      payDialog: false,      
      panelModel: null,
      vuetifyColors: colors,
      bonusRoublesAmount: null,
      disableOptions: false,
      promocodeLoading: false,
      bonusBalance: 0,
      bonusBalanceLoading: false,
    }
  },
  props: {
    order: Object
  },
  created() {
    console.log(this.$store.getters.STATIC);
    this.isAcquiring = (this.$store.getters.STATIC.shopAcquiringActive && this.$store.getters.STATIC.acquiringActive)
    this.isBonus = (this.$store.getters.STATIC.bonusRoublesEnable && this.order.payStatus === null)
    if (!this.order.lkBonusActive) this.isBonus = false
  },
  computed: {
    dateOrder() {
      return moment(this.order.date).format('DD.MM.YYYY HH:mm:ss')
    },
    payStatusLabel() {
      let payStatusLabel = 'Счёт не создан'
      switch (this.order.payStatus) {
        case 0 : {
          payStatusLabel = 'Не оплачено';
          break;
        }
        case 1 : {
          payStatusLabel = 'Оплачено';
          break;
        }
        case 2 : {
          payStatusLabel = 'Возврат';
          break;
        }
      }
      return payStatusLabel
    },
    payStatusColor() {
      let payStatusColor = 'secondary'
      switch (this.order.payStatus) {
        case 0 : {
          payStatusColor = 'warning';
          break;
        }
        case 1 : {
          payStatusColor = 'success';
          break;
        }
        case 2 : {
          payStatusColor = 'danger';
          break;
        }
      }
      return payStatusColor
    },
    // Получить цену услуг в талоне
    getTotalServicesAmout() {
      let services = [];
      if (this.order && this.order.items) {
        services = this.order.items ?? [];
      }
      return services.reduce( (prev, val) => prev + (val.price * val.total), 0);
    },
    
    // Максимальное количество бонусов которое можно потратить
    maxBonusThatCanBeSpend() {
      const servicesPrice = this.getTotalServicesAmout / 100;
      return Math.min( Math.trunc( servicesPrice * (this.$store.getters.STATIC.maxBonusRoublesPercent / 100) ), this.bonusBalance );
    },

    showCode() {
      return this.order.status.tag != 'completed' && this.order.status.tag != 'cancel' && this.order.status.tag != 'expectPayment'
    },
    itemsPrice() {
      return this.order.items?.reduce((accumulator, item) => {
        return accumulator + (item.hidePrice ? 0 : (item.price * item.total))
      }, 0)
    },
    totalPrice() {
      return this.itemsPrice + (this.order.delivery ? this.order.delivery.price : 0)
    },
    totalPriceWithBonus() {
      if (
          this.isBonus &&
          this.panelModel === 0 && this.bonusRoublesAmount <= this.maxBonusThatCanBeSpend && 
          this.bonusRoublesAmount !== '' && this.bonusRoublesAmount != null && this.bonusRoublesAmount > 0 &&
          this.bonusRoublesAmount == Math.trunc(this.bonusRoublesAmount)
      )
      return this.itemsPrice + (this.order.delivery ? this.order.delivery.price : 0) - (this.bonusRoublesAmount * 100)
      else
      return this.totalPrice

    },
    approved() {
      return this.order.delivery && this.order.delivery.approved
    },
    isApproximately() {
      return this.order.items?.reduce((accumulator, item) => {

        return !accumulator ? item.approximately : true
      }, 0)
    },
    getCountAll() {
      return this.order.items?.reduce((accumulator, item) => {
        return accumulator + item.total
      }, 0)
    },    
    
  },
  methods: {
    readyPay() {
      this.loadingPay = true
      this.getBonusBalance()
    },
    // Получить бонусный баланс
    getBonusBalance() {
      this.bonusBalanceLoading = true;
      this.$http.post(window.config.apiUrl + '/customer-bonuses/get-balance')
        .then( ({data}) => {
          if (data.status == 'success') {
            this.bonusBalance = data.balance;
            this.bonusBalanceLoading = false;
          }
        } )
        .catch( error => console.log(error) )
        .finally(() => {
          this.payDialog = true
        });
    }, 
   

    async pay() {
      this.loadingPay = true
      try {
        let bonus = 0

        if (
          this.isBonus &&
          this.panelModel === 0 && this.bonusRoublesAmount <= this.maxBonusThatCanBeSpend && 
          this.bonusRoublesAmount !== '' && this.bonusRoublesAmount != null && this.bonusRoublesAmount > 0 &&
          this.bonusRoublesAmount == Math.trunc(this.bonusRoublesAmount)
        ) bonus = this.bonusRoublesAmount
        const pay = await orders.pay(this.order.number, bonus)
        if (pay.status == 'success') {
          window.top.location.replace(pay.message)
        } else {
          this.alertText = pay.message
          this.alert = true;
        }
      } catch (e) {
        return null
      }
      this.loadingPay = false
    },
    async deleteOrder() {
      this.dialogDelete = false
      try {
        const result = await orders.delete(this.order.number, this.reason)
        if (result.status == 'success') {
          this.$emit('delete', this.order.number);
        } else {
          this.alertText = result.message
          this.alert = true;
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    payDialog: function (val) {
      if (val == false) this.loadingPay = false
    },
  }
}
</script>