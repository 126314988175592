<template>
    <div class="row">
        <div class="col-3 col-xl-1 col-lg-1 col-md-2 ">
            <div class="productImageMini cursor-pointer">
                <a :href="url" class="text-decoration-none">
                    <v-img
                        contain       
                        :src="item.image ? item.image : require('@/assets/noimage.png')"
                    />
                </a>
            </div>           
        </div>     


        <div class="col-9 col-xl-3 col-lg-4 col-md-4 d-flex align-start justify-content-end order-md-1">
            <div class="d-flex flex-column">  
                <div v-if="(item.saldo > 0 && item.total <= item.saldo) || payStatus > 0" class="d-flex flex-column align-items-end">    
                    <div v-if="item.isCertificate && item.certificateId && item.status.name == 'Выполнен'" class="text-end mb-2 w-100">
                       
                        <v-progress-linear 
                            v-if="loading"
                            color="success"
                            indeterminate
                            rounded
                            height="6"
                            class="w-100"
                        />
                        <v-chip
                        v-else
                            color="success"
                            style="cursor: pointer"                                                      
                            @click="openLink(item.certificateId, item.name)"
                            small
                        >
                        Скачать
                        </v-chip>
                    </div>   
                    <div v-else class="text-end mb-2">
                        <v-chip
                            :color="item.status.color"
                            small
                        >
                        {{ item.status.name }}
                        </v-chip>
                    </div>  
                    <div class="subtitle-1  font-weight-medium price d-flex align-baseline">
                        <span class="subtitle-2 mr-1">Цена: </span>
                        <b class="text--primary">
                            <PriceTotal :price="item.price" :count="item.total" :approximately="item.approximately" :hidePrice="item.hidePrice" />
                        </b>
                    </div>
                    <div class="d-flex justify-content-end text--disabled pt-1">
                        <span>{{ item.total }} {{ item.unitShort }}</span>
                        <span v-if="!item.hidePrice" class="mr-1 ml-1">x</span>
                        <div v-if="!item.hidePrice">
                            <PriceTotal :price="item.price" />
                        </div>
                    </div>
                </div>
                <div v-else class="subtitle-1  font-weight-medium price d-flex align-baseline">
                    <span class="subtitle-2 mr-1">Нет в наличии</span>
                </div>
                
            </div>
        </div>     
           
        <div class="col-12 col-xl-8 col-lg-7 col-md-6">
            <div class="productTitle line-clamp">
                <a :href="url" class="text-decoration-none"> {{ item.name }} </a>
            </div>
            <div class="caption text--disabled">
                <span>Артикул: </span>
                <span>{{ item.article }}</span>
            </div>
        </div>         
        
    </div>
</template>

<script>

import PriceTotal from '@/components/orders/PriceTotal.vue'
import {orders} from '@/services/api'

export default {
    name: 'OrderItem',
    data() {
        return{
            loading: false,
            url: this.$store.getters.STATIC.shopUrl+'/product/'+this.item.friendlyUrl
        }
    },
    props: {
        item: Object,
        payStatus: Number
    },
    components: {
        PriceTotal
    },
    methods: {
        async openLink(id, name) {
        this.loading = true
        try {
            const pdfContent = await orders.certificate(id)
           
            const href = window.URL.createObjectURL(pdfContent);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = name + '.pdf';

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        } catch (e) {
            console.log(e)
        } finally {
            this.loading = false
        }
        },
       
    }
    
}
</script>

<style scoped> 
.productImageMini {   
    cursor: pointer; 
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden; 
    aspect-ratio: 1;
}
.productImageMini .v-image {
    height: 100%;
}

.productImageMini:before {
    content: "";
    padding-top: 100%;
    float: left;
}


.productTitle {
    color: #555;
    cursor: pointer;
}

.productTitle:hover {
    color: #444;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.price {
    font-size: 24px;
    margin: 0;
}
</style>
